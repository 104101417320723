<template>
  <div class="qz-login">
    <el-form class="container" ref="ruleForm" :model="testForm" :rules="rules">
      <el-form-item label="用户名:" prop="name">
        <el-input placeholder="请输入用户名" v-model="testForm.name"></el-input>
      </el-form-item>
      <el-form-item label="密码:" prop="phone">
        <el-input
          placeholder="请输入密码"
          type="password"
          v-model="testForm.phone"
        ></el-input>
      </el-form-item>
      <el-form-item class="con1">
        <el-button
          type="primary"
          :loading="loading"
          @click="submitForm('ruleForm')"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script lang="ts">
// import { sendRequest } from "../../api/contact";
import { Message } from "element-ui";

export default {
  name: "Contact",
  components: {},
  data() {
    return {
      formVisible: false,
      loading: false,
      testForm: {
        name: "1",
        phone: "1",
      },
      rules: {
        name: [
          { required: true, message: "请输入姓名", blur: "blur" },
          { min: 1, max: 10, message: "姓名不超过 10 个字符", blur: "blur" },
        ],
        phone: [{ required: true, message: "请输入密码", blur: "blur" }],
      },
    };
  },
  methods: {
    async submitForm(ruleForm) {
      this.$refs[ruleForm].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          // var postData = {
          //   name: this.testForm.name, // 姓名
          //   mobile: this.testForm.phone, // 联系方式
          // };
          // let sendResult = await sendRequest(postData);
          // console.log(sendResult);
          //alert("提交成功！");
          // Notification.success({
          //   title: "登录成功！",
          //   message: "请等待客服处理",
          // });
          Message.success("登录成功。");
          this.$router.push("/admin");
        } else {
          // Message.error("登录失败。");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scope>
.qz-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .container {
    padding: 20px;
    width: 300px;
    box-shadow: 0px 4px 14px 2px rgba(14, 10, 3, 0.23);
    border-radius: 11px;
  }
}
.el-form.container .el-button {
  width: 100%;
  height: 40px;
}
</style>
